<template>
  <div class="filter-options filter-options--influencer-discovery" :class="{ light: host.theme.type === 'light' }">
    <v-row>
      <v-col v-for="item in platformFilters" :key="'platform-' + item" cols="6" sm="4" md="3" lg="2">
        <component :is="item" :platform="platform" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
// const FilterAudienceType = () => import(/* webpackChunkName: "filter-audience-type" */ "@/blocks/influencer-discovery/FilterAudienceType.vue")
const FilterAudienceCredibilityClass = () => import(/* webpackChunkName: "filter-audience-credibility" */ "@/blocks/influencer-discovery/FilterAudienceCredibilityClass.vue")
const FilterGrowthFollowers = () => import(/* webpackChunkName: "filter-growth-followers" */ "@/blocks/influencer-discovery/FilterGrowthFollowers.vue")
const FilterGrowthLikes = () => import(/* webpackChunkName: "filter-growth-likes" */ "@/blocks/influencer-discovery/FilterGrowthLikes.vue")
const FilterGrowthViews = () => import(/* webpackChunkName: "filter-growth-views" */ "@/blocks/influencer-discovery/FilterGrowthViews.vue")
const FilterAccountType = () => import(/* webpackChunkName: "filter-account-type" */ "@/blocks/influencer-discovery/FilterAccountType.vue")
const FilterPartnership = () => import(/* webpackChunkName: "filter-partnership" */ "@/blocks/influencer-discovery/FilterPartnership.vue")
const FilterEngagements = () => import(/* webpackChunkName: "filter-engagements" */ "@/blocks/influencer-discovery/FilterEngagements.vue")
const FilterLastPosted = () => import(/* webpackChunkName: "filter-last-posted" */ "@/blocks/influencer-discovery/FilterLastPosted.vue")
const FilterWorkCompany = () => import(/* webpackChunkName: "filter-work-company" */ "@/blocks/influencer-discovery/FilterWorkCompany")
const FilterReelViews = () => import(/* webpackChunkName: "filter-reel-views" */ "@/blocks/influencer-discovery/FilterReelViews.vue")
const FilterEthnicity = () => import(/* webpackChunkName: "filter-ethnicity" */ "@/blocks/influencer-discovery/FilterEthnicity.vue")
const FilterLookAlike = () => import(/* webpackChunkName: "filter-lookalike" */ "@/blocks/influencer-discovery/FilterLookAlike.vue")
const FilterFollowers = () => import(/* webpackChunkName: "filter-followers" */ "@/blocks/influencer-discovery/FilterFollowers.vue")
const FilterTalksAbout = () => import(/* webpackChunkName: "filter-talks-about" */ "@/blocks/influencer-discovery/FilterTalksAbout")
const FilterLocation = () => import(/* webpackChunkName: "filter-location" */ "@/blocks/influencer-discovery/FilterLocation.vue")
const FilterLanguage = () => import(/* webpackChunkName: "filter-language" */ "@/blocks/influencer-discovery/FilterLanguage.vue")
const FilterInterest = () => import(/* webpackChunkName: "filter-interest" */ "@/blocks/influencer-discovery/FilterInterest.vue")
const FilterContacts = () => import(/* webpackChunkName: "filter-contacts" */ "@/blocks/influencer-discovery/FilterContacts.vue")
const FilterKeywords = () => import(/* webpackChunkName: "filter-keywords" */ "@/blocks/influencer-discovery/FilterKeywords.vue")
const FilterTextTags = () => import(/* webpackChunkName: "filter-text-tags" */ "@/blocks/influencer-discovery/FilterTextTags.vue")
// const FilterOfficial = () => import(/* webpackChunkName: "filter-official" */ "@/blocks/influencer-discovery/FilterOfficial.vue")
const FilterJobTitle = () => import(/* webpackChunkName: "filter-job-title" */ "@/blocks/influencer-discovery/FilterJobTitle")
const FilterSchool = () => import(/* webpackChunkName: "filter-school" */ "@/blocks/influencer-discovery/FilterSchool")
const FilterGender = () => import(/* webpackChunkName: "filter-gender" */ "@/blocks/influencer-discovery/FilterGender.vue")
const FilterShares = () => import(/* webpackChunkName: "filter-shares" */ "@/blocks/influencer-discovery/FilterShares.vue")
// const FilterBrand = () => import(/* webpackChunkName: "filter-brand" */ "@/blocks/influencer-discovery/FilterBrand.vue")
const FilterViews = () => import(/* webpackChunkName: "filter-views" */ "@/blocks/influencer-discovery/FilterViews.vue")
const FilterSaves = () => import(/* webpackChunkName: "filter-saves" */ "@/blocks/influencer-discovery/FilterSaves.vue")
const FilterAge = () => import(/* webpackChunkName: "filter-age" */ "@/blocks/influencer-discovery/FilterAge.vue")
const FilterBio = () => import(/* webpackChunkName: "filter-bio" */ "@/blocks/influencer-discovery/FilterBio.vue")

export default {
  name: "FilterOptions",

  props: {
    platform: {
      type: String,
      required: true
    }
  },

  components: {
    // FilterAudienceType,
    FilterAudienceCredibilityClass,
    FilterGrowthFollowers,
    FilterGrowthLikes,
    FilterGrowthViews,
    FilterAccountType,
    FilterPartnership,
    FilterEngagements,
    FilterWorkCompany,
    FilterLastPosted,
    FilterTalksAbout,
    FilterEthnicity,
    FilterFollowers,
    FilterReelViews,
    FilterLookAlike,
    FilterInterest,
    FilterLocation,
    FilterLanguage,
    FilterContacts,
    FilterKeywords,
    FilterTextTags,
    FilterJobTitle,
    // FilterOfficial,
    FilterSchool,
    FilterShares,
    FilterGender,
    // FilterBrand,
    FilterViews,
    FilterSaves,
    FilterAge,
    FilterBio
  },

  data: () => ({
    validatorMap: {
      "filter-ethnicity": constants.model.user.allowedServices.filterDiscoveryEthnicity
    },

    filterComponents: {
      instagram: [
        "filter-location",
        "filter-language",
        // "filter-brand",
        "filter-partnership",
        "filter-interest",
        "filter-gender",
        "filter-age",
        "filter-keywords",
        // "filter-audience-type",
        "filter-look-alike",
        "filter-bio",
        "filter-followers",
        "filter-engagements",
        "filter-reel-views",
        "filter-contacts",
        "filter-growth-followers",
        "filter-last-posted",
        "filter-account-type",
        "filter-text-tags",
        "filter-ethnicity",
        "filter-audience-credibility-class"
      ],
      youtube: [
        "filter-location",
        "filter-language",
        "filter-gender",
        "filter-age",
        // "filter-audience-type",
        "filter-keywords",
        "filter-look-alike",
        "filter-bio",
        "filter-followers",
        "filter-engagements",
        "filter-views",
        "filter-contacts",
        "filter-growth-followers",
        "filter-growth-views",
        "filter-last-posted"
        // "filter-official"
      ],
      tiktok: [
        "filter-location",
        "filter-language",
        "filter-gender",
        "filter-age",
        "filter-keywords",
        "filter-look-alike",
        "filter-bio",
        "filter-followers",
        "filter-engagements",
        "filter-views",
        "filter-shares",
        "filter-saves",
        "filter-contacts",
        "filter-growth-followers",
        "filter-growth-likes",
        "filter-last-posted",
        "filter-text-tags"
      ],
      snapchat: [
        "filter-location",
        "filter-language",
        "filter-gender",
        "filter-age",
        "filter-followers",
        "filter-bio",
        "filter-contacts"
      ],
      linkedin: [
        "filter-location",
        "filter-language",
        "filter-followers",
        // "filter-keywords",
        "filter-job-title",
        "filter-talks-about",
        "filter-work-company",
        "filter-school"
        // Industry
      ]
    }
  }),

  computed: {
    /**
     * Get all the filters for this platform
     *
     * @returns {Array}
     */
    platformFilters() {
      return this.filterComponents[this.platform].filter((item) => {
        return this.validatorMap[item] ? this.$store.getters["auth/isServiceAllowed"](this.validatorMap[item]) : true
      })
    }
  }
}
</script>

<style lang="stylus">
.filter-options--influencer-discovery.light
  .v-icon
    color var(--v-secondary) !important

  .filter-title
    color #4c4c4c !important
</style>
